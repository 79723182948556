"use client";

import { ButtonHTMLAttributes, forwardRef } from "react";
import { BusinessJukeboxIcon, PersonalJukeboxIcon, PlusCircleIcon } from "@components/icons";
import Link from "next/link";
import styles from "./JukeboxButton.module.css";
import { LoadingIcon } from "@components/custom";
import { Slot } from "@radix-ui/react-slot";
import { cn } from "@lib/utils";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string;
  isLoading?: boolean;
  variant: "business" | "personal" | "create" | "redirect" | "button";
  asChild?: boolean;
}

const JukeboxButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, href = "", isLoading = false, variant, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp className={cn(styles.jukebox_button, className)} ref={ref} {...props}>
        {variant === "business" && (
          <Link className="w-fit" href="/get-started/signup/business">
            <BusinessJukeboxIcon className="w-24 h-24 sm:w-36 sm:h-36" />
            <h5>Business</h5>
          </Link>
        )}
        {variant === "personal" && (
          <Link className="w-fit" href="/get-started/signup/personal">
            <PersonalJukeboxIcon className="w-24 h-24 sm:w-36 sm:h-36 stroke-none" />
            <h5>Personal</h5>
          </Link>
        )}
        {variant === "create" && (
          <div className="w-fit">
            {isLoading ? (
              <LoadingIcon />
            ) : (
              <PlusCircleIcon className="w-24 h-24 sm:w-40 sm:h-40 !stroke-white" />
            )}
          </div>
        )}
        {variant === "redirect" && (
          <div className="w-fit">
            {isLoading ? (
              <LoadingIcon />
            ) : (
              <Link className="w-fit" href={href}>
                <PersonalJukeboxIcon className="w-24 h-24 sm:w-36 sm:h-36 stroke-none" />
              </Link>
            )}
          </div>
        )}
        {variant === "button" && (
          <div className="w-fit">
            {isLoading ? (
              <LoadingIcon />
            ) : (
              <PersonalJukeboxIcon className="w-24 h-24 sm:w-36 sm:h-36 stroke-none" />
            )}
          </div>
        )}
      </Comp>
    );
  },
);
JukeboxButton.displayName = "JukeboxButton";
export default JukeboxButton;
