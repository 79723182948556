"use client";

import { LoadingIcon, SpotifyCredentialManager } from "@components/custom";
import * as Form from "@radix-ui/react-form";
import Link from "next/link";
import { useRouter } from "next/navigation";
import type { ChangeEvent, KeyboardEvent, SyntheticEvent } from "react";
import { Suspense, useState } from "react";
import styles from "./page.module.css";
import { Input } from "@components/ui/input";

const createInitialInputs = [
  { id: 0, value: "" },
  { id: 1, value: "" },
  { id: 2, value: "" },
  { id: 3, value: "" },
  { id: 4, value: "" },
  { id: 5, value: "" },
];

export default function Join() {
  const [inputValues, setInputValues] = useState(createInitialInputs);
  const [isLoading, setIsLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState({
    jukeboxId: false,
  });
  const router = useRouter();

  const handleInputChange = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const { value, nextSibling } = event.target;
    setServerErrors((prev) => ({ ...prev, jukeboxId: false }));
    setInputValues((prevState) => {
      const newState = [...prevState];
      newState[index].value = value.toUpperCase();
      return newState;
    });

    if (index < 5 && value) {
      (nextSibling as HTMLInputElement).focus();
    }
  };

  const handleKeyDown = (index: number) => (event: KeyboardEvent) => {
    const { key, target } = event;
    const inputTarget = target as HTMLInputElement;
    const prev = inputTarget?.previousSibling as HTMLElement;

    if (key === "ArrowLeft" && index > 0) {
      prev.focus();
    } else if (key === "ArrowRight" && index < 5) {
      (inputTarget.nextSibling as HTMLElement).focus();
    } else if (key === "Backspace" && index > 0 && !inputTarget?.value) {
      prev.focus();
    }

    if (key === "Backspace" && index > 0 && !inputTarget?.value) {
      prev.focus();
    }
  };

  const handleCodeSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    const targetId = inputValues.map((item) => item.value).join("");

    if (targetId.length === 6) {
      setIsLoading(true);
      router.push(`/jukebox/${targetId}`);
      setIsLoading(false);
    }
  };
  return (
    <main className="flex flex-col justify-center items-center">
      <Suspense>
        <SpotifyCredentialManager variant="GUEST" />
      </Suspense>
      <Form.Root
        className="form my-auto"
        onClearServerErrors={() => setServerErrors({ jukeboxId: false })}
      >
        <Form.Field className="form_field" name="jukeboxId" serverInvalid={serverErrors.jukeboxId}>
          <Form.Label asChild>
            <p className="mb-3">Enter code to start.</p>
          </Form.Label>
          <div className="flex gap-2">
            {inputValues.map((item, index) => (
              <Form.Control key={item.id} asChild required>
                <Input
                  className={styles.square_input}
                  maxLength={1}
                  type="text"
                  value={item.value}
                  onChange={handleInputChange(index)}
                  onKeyDown={handleKeyDown(index)}
                />
              </Form.Control>
            ))}
          </div>
          <div className="w-full h-[5rem] pt-[1rem] flex items-begin justify-center text-center text-rocola-red">
            <Form.Message match="valueMissing">Empty pin, please try again.</Form.Message>
            <Form.Message forceMatch={serverErrors.jukeboxId} match="typeMismatch">
              We didn&apos;t recognize that pin, please try again.
            </Form.Message>
          </div>
        </Form.Field>
        <Form.Submit asChild>
          <button className="button" type="submit" onClick={handleCodeSubmit}>
            {isLoading ? <LoadingIcon stroke={5} size={30} speed={1.5} /> : <p>Enter</p>}
          </button>
        </Form.Submit>
      </Form.Root>
      {/* {!isSignedIn && <ConnectSpotifyButton searchParams={{}} variant="guest" />} */}
      <p className="text-center sm:text-base my-1">
        Create your own jukebox with
        <Link className="ml-1 text-rocola-blue sm:text-base" href="/about">
          Rocola
        </Link>
      </p>
    </main>
  );
}
