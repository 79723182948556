"use client";

import { useEffect } from "react";
import type {} from "ldrs";

declare module "react" {
  namespace JSX {
    interface IntrinsicElements {
      "l-ring": {
        size?: string | number;
        color?: string | number;
        speed?: string | number;
      };
    }
  }
}

export default function LoadingIcon({
  size = 75,
  color = "white",
  speed = 1.5,
  ...props
}: {
  size?: string | number | undefined;
  color?: string | number | undefined;
  speed?: string | number | undefined;
  stroke?: string | number | undefined;
  "bg-opacity"?: string | number | undefined;
}) {
  useEffect(() => {
    async function getLoader() {
      const { ring } = await import("ldrs");
      ring.register();
    }
    getLoader();
  }, []);
  return <l-ring color={color} size={size} speed={speed} {...props}></l-ring>;
}
